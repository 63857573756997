// stylelint-disable declaration-no-important, property-no-vendor-prefix
.ps {
  overflow: hidden !important;
  -ms-touch-action: auto;
  touch-action: auto;
  -ms-overflow-style: none;
  overflow-anchor: none;
}

.ps__rail-x {
  position: absolute;
  bottom: 0;
  display: none;
  height: 15px;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
}

.ps__rail-y {
  position: absolute;
  @include ltr {
    right: 0;
  }
  @include rtl {
    left: 0;
  }
  display: none;
  width: 15px;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: .9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  position: absolute;
  bottom: 2px;
  height: 6px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
}

.ps__thumb-y {
  position: absolute;
  @include ltr {
    right: 2px;
  }
  @include rtl {
    left: 2px;
  }
  width: 6px;
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  height: 11px;
  background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  width: 11px;
  background-color: #999;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
