body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// stylelint-disable declaration-no-important
.font-xs {
  font-size: .75rem !important;
}

.font-sm {
  font-size: .85rem !important;
}

.font-lg {
  font-size: 1rem !important;
}

.font-xl {
  font-size: 1.25rem !important;
}

.font-2xl {
  font-size: 1.5rem !important;
}

.font-3xl {
  font-size: 1.75rem !important;
}

.font-4xl {
  font-size: 2rem !important;
}

.font-5xl {
  font-size: 2.5rem !important;
}

[class^="text-value"] {
  font-weight: 600;
}

.text-value-xs {
  font-size: ($font-size-base * .75);
}

.text-value-sm {
  font-size: ($font-size-base * .85);
}

.text-value {
  font-size: $font-size-base;
}

.text-value-lg {
  font-size: ($font-size-base * 1.5);
}

.text-value-xl {
  font-size: ($font-size-base * 1.75);
}

.text-white .text-muted {
  color: rgba(255, 255, 255, .6) !important;
}
