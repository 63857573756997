@mixin badge-variant($bg, $color) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root #{str-replace(#{&}, ".badge-#{$color}", " ")} a.badge-#{$color} {
    @include hover-focus() {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    }
  }
}
