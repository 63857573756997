.c-footer {
  display: flex;
  flex: 0 0 $footer-height;
  flex-wrap: wrap;
  align-items: center;
  height: $footer-height;
  padding: 0 $spacer;

  &[class*="bg-"] {
    border-color: rgba($black, .1);
  }

  &.c-footer-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
}

@include themes($footer-theme-map, $parent: ".c-footer") {
  color: themes-get-value("footer-color");
  background: themes-get-value("footer-bg");
  @include borders(themes-get-value("footer-borders"));
}
