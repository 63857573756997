.c-chart-wrapper canvas {
  width: 100%;
}
// stylelint-disable selector-no-qualifying-type, selector-type-no-unknown
base-chart.chart {
  display: block;
}

canvas {
  user-select: none;
}
